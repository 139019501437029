<template>
  <div class="box-form box-form-products">
    <v-form>
      <v-card-title class="modal-cadastro-titulo" v-translate='{ name: entity ? entity.name : "", nameUp: getEntityType(2) }'>
        Cadastro de comissão %{ nameUp }: %{ name }
      </v-card-title>
      <v-card-text class="modal-cadastro-form">
        <v-skeleton-loader
          :loading="loadingSkeleton"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <!-- <v-text-field type="tel" v-model="item.value" label="Comissão"  /> -->
              <!-- <percentage :error-message="comissaoErrors" v-model.number="item.value" :suffix="'%'" label="Porcentagem" /> -->
              <v-currency-field v-model="item.value" :label="'Porcentagem'" :suffix="'%'"  :error-messages="comissaoErrors" class="percentage" @input="$v.item.value.$touch()" @blur="$v.item.value.$touch()"/>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
              <v-btn :color="variables.colorPrimary" @click="cancel" class="br-btn br-btn-cancelar br-btn-entity-products mr-2" :width="70">Cancelar</v-btn>
              <v-btn
                type="submit"
                :color="variables.colorPrimary"
                @click.prevent="submit"
                class="br-btn br-btn-entity-products"
                :loading="loading"
                :width="70"
                v-if="permiteAcao($route, 'edit') || permiteAcao($route, 'add')">Cadastrar</v-btn>
            </v-col>
          </v-row>
        </v-skeleton-loader>
      </v-card-text>
    </v-form>

    <v-card-text class="modal-cadastro-form" v-if="item.id">
      <v-data-table
        :headers="headers"
        :items="[item]"
        width="300px"
        height="auto"
        flat
        :loading="loadingTable"
        hide-default-footer>

        <template v-slot:item.aliquot="{ item }">
          {{ item.aliquot | percentage }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="permiteAcao($route, 'edit')">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="permiteAcao($route, 'delete')">
            delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxValue } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import variables from '@/assets/styles/helpers/_variables.scss'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

import validacao from './validacao'


export default {
  name: 'BrModalCadastroAts',
  mixins: [validationMixin],
  directives: {mask},
  components: {
    // percentage: () => import('@/views/components/percentage') 
  },
  props: {
    entity: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    activeModal: false,
    loading: false,
    search: '',
    headers: [
      { class: 'table-header', text: 'ID', value: 'id' },
      { class: 'table-header', text: 'Alíquota', value: 'aliquot' },
      { class: 'table-header', text: 'Data', value: 'date' }
    ],
    itemPerPage: [5, 10, 50, 100, {text: 'Todos', value: -1}],
    loadingTable: false,
    loadingSkeleton: false
  }),

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('credenciadorComission', ['item']),
    
    ...validacao,

    variables: () => variables
  },

  watch: {
    'entity.id' (id) {
      if (id >= 0) {
        this.loadingSkeleton = true
        this.getItem({ entityId: id })
        .catch(() => console.log('Não possui comissão cadastrada!'))
        .finally(() =>  this.loadingSkeleton = false)
      }
    }
  },

  mounted () {
    if (this.entity.id >= 0) {
      this.getItem({ entityId: this.entity.id })
         .catch(() => console.log('Não possui comissão cadastrada!'))
         .finally(() =>  this.loadingSkeleton = false)
    }
  },
  
  methods: {
    ...mapActions('credenciadorComission', ['getItem', 'cadastrarItem', 'limparItem', 'deletarItem']),

    cancel () {
      this.limparItem()
      this.$v.$reset()
    },

    cadastrarComissao(dados) {
      this.cadastrarItem(dados)
      .then(() => {
        successSnackbar('Comissão registrada com sucesso')
        this.$emit('close-modal')
      })
      .catch(err => errorSnackbar(err.error))
      .finally(() => this.loading = false)
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true

      const dados = {
        value: this.item.value,
        entities: [
          { entityId: this.entity.id }
        ]
      }

      if (this.item.id >= 0) {
         this.$swal({
          icon: 'warning',
          text: `Comissão já cadastrada, deseja substituir?`,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.value) {
            this.cadastrarComissao(dados) 
            return
          }
        })

        return
      }

      this.cadastrarComissao(dados)
    },
  },

  validations () {
    return  {
      item: {
        value: { required, maxValue: maxValue(30) },
      },

      entity: {
        id: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../../assets/styles/components/formularios';
  @import '../../../../../../assets/styles/components/modal-cadastro';
  .br-btn-entity-products {
    margin-top: 10px;
    min-width: auto !important;
    font-size: 0.8em !important;
  }
</style>